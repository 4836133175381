<template>
  <div>
    <v-card class="pipeline-item">
      <v-card-title class="d-flex flex-column pipeline-item__title center">
        <div
          :class="[`banner-status ${pipelineStatus.status}`, {
            [`pipeline-${pipelineStatus.status}-started`]: pipelineStatus.started,
          }]"/>
        <router-link
          :to="{
            name: 'envComponentPipeline',
            params: {
              orgCanonical,
              projectCanonical: pipeline.project.canonical,
              envCanonical: pipeline.environment.canonical,
              componentCanonical: pipeline.component.canonical,
              pipelineCanonical: pipeline.name,
              backRouteTo: 'pipelinesOverview',
            },
          }"
          class="font-weight-bold font-size-base">
          {{ pipeline.name }}
        </router-link>
      </v-card-title>
      <div>
        <v-row
          v-if="!_.isEmpty(pipeline.jobs)"
          class="pipeline-grid ">
          <v-col
            v-for="(groupRank, keyRank, indexRank) in rankedJobs"
            :key="indexRank"
            class="parallel-grid d-flex flex-column">
            <v-row
              v-for="(job, keyJob, indexJob) in groupRank"
              :key="indexJob"
              :class="[
                'pipeline-job',
                getJobIdStatus(job).started ? `job-${getJobIdStatus(job).status}-started` : getJobIdStatus(job).status,
              ]">
              <CyTooltip right>
                <template #activator="{ on }">
                  <v-col
                    class="no-padding d-flex"
                    v-on="on">
                    <router-link
                      :to="{
                        name: 'builds',
                        params: {
                          orgCanonical,
                          projectCanonical: pipeline.project.canonical,
                          pipelineCanonical: pipeline.name,
                          envCanonical: pipeline.environment.canonical,
                          componentCanonical: pipeline.component.canonical,
                          jobCanonical: job.name,
                          buildId: getJobIdStatus(job).id,
                          backRouteTo: 'pipelinesOverview',
                        },
                      }"
                      class="joblink"/>
                  </v-col>
                </template>
                <span>{{ job.name }}</span>
              </CyTooltip>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-else
          class="pipeline-grid"
          align="center"
          justify="center">
          <v-progress-circular
            indeterminate
            color="secondary"/>
        </v-row>
      </div>
      <v-card-actions>
        <v-row
          v-if="pipelineAge.status"
          class="align-center justify-center">
          <v-icon :class="`${pipelineStatus.status}-color`">
            {{ pipelineIcon(pipelineStatus.status) }}
          </v-icon>
          <span :class="['ml-2', `${pipelineStatus.status}-color`, 'font-weight-bold']">
            {{ pipelineAge.status }}
          </span>
        </v-row>
        <CyTooltip
          v-else
          top>
          <template #activator="{ on: rowTooltip }">
            <v-row
              class="align-center justify-center"
              v-on="rowTooltip">
              <v-icon :class="`${pipelineStatus.status}-color`">
                {{ pipelineIcon(pipelineStatus.status) }}
              </v-icon>
              <span :class="['ml-2', `${pipelineStatus.status}-color`, 'font-weight-bold']">
                {{ pipelineAge.duration }}
              </span>
            </v-row>
          </template>
          <span>{{ pipelineAge.date }}</span>
        </CyTooltip>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CyViewsPipelinePreview',
  props: {
    pipeline: {
      type: Object,
      required: true,
    },
  },
  computed: {
    jobsStatus () {
      return _.map(this.pipeline.jobs, 'finished_build.status')
    },
    pipelineAge () {
      if (_.map(this.pipeline.jobs, 'next_build.status').includes('started')) return { status: 'Running' }
      if (this.pipeline.paused) return { status: 'Paused' }
      if (this.pipelineStatus.status === 'pending') return { status: 'Pending' }
      const statusToCheck = this.pipelineStatus.status
      const filteredStatusJobs = _.filter(this.pipeline.jobs, function (o) { return _.get(o, 'finished_build.status') === statusToCheck })
      const jobFiltered = _.orderBy(filteredStatusJobs, ['finished_build.end_time'], ['desc'])
      const buildTime = _.get(jobFiltered[0], 'finished_build.end_time')

      if (!buildTime) return { status: 'Unknown' }
      const diff = $date.$diff($date.fromUnixTime(buildTime), Date.now())

      return {
        duration: `${diff.days}d ${diff.hours}h ${diff.minutes}m`,
        date: $date.format($date.fromUnixTime(buildTime), 'MMMM d, yyyy h:mm a'),
      }
    },
    pipelineStatus () {
      let started = false
      if (_.map(this.pipeline.jobs, 'next_build.status').includes('started')) started = true
      if (this.pipeline.paused) return { status: 'paused', started }
      // if a job is started, pipeline is running
      // if no jobs in finished builds, then everything is pending
      if (_.isEmpty(this.pipeline.jobs, 'finished_builds')) return { status: 'pending', started }
      // if no job in next_build, check all finished jobs.
      // if jobs are all success, pipeline is successful, return also the most recent job
      if (this.jobsStatus.includes('paused')) return { status: 'paused', started }
      if (this.jobsStatus.includes('failed')) return { status: 'failed', started }
      if (this.jobsStatus.includes('errored')) return { status: 'errored', started }
      if (this.jobsStatus.includes('aborted')) return { status: 'aborted', started }
      if (this.jobsStatus.includes('succeeded')) return { status: 'succeeded', started }
      // if no errors happened then everything should be success
      return { status: 'pending', started }
    },
    rankedJobs () {
      const depths = this.jobDepths({}, {}, _.cloneDeep(this.pipeline.jobs))
      const result = [...new Array(_.uniq(Object.values(depths)).length)].map((e) => [])
      this.pipeline.jobs.forEach((job) => _.isArray(result[depths[job.name]]) && result[depths[job.name]].push(job))
      return result
    },
  },
  methods: {
    pipelineIcon (pipelineStatus) {
      switch (pipelineStatus) {
        case 'failed': return 'error'
        case 'paused': return 'pause_circle_filled'
        case 'errored': return 'warning'
        case 'aborted': return 'cancel'
        case 'pending': return 'pending'
        case 'succeeded': return 'check_circle'
        default: return 'pending'
      }
    },
    getJobIdStatus (job) {
      const id = job.hasOwnProperty('next_build') ? _.get(job, 'next_build.id') : _.get(job, 'finished_build.id')
      return {
        id,
        status: job.paused ? 'paused' : _.get(job, 'finished_build.status', 'pending'),
        started: job.next_build,
      }
    },
    jobDepths (calculations, depths, jobs) {
      if (_.isEmpty(jobs)) return depths
      // Removing the job from the job list to run the algo on it
      const job = jobs.shift()
      // Compute the dependencies which is a list of the inputs + passed
      const dependencies = _.filter(_.flatMap(_.map(job.inputs, 'passed')))
      // Get the value associated to a job name stored in depths and included in dependencies
      const dependencyDepths = _.without(dependencies.map((dep) => depths[dep]))

      // If we have dependencies already ranked, then take the max rank and add 1. Else, create a rank 0
      const value = !_.isEmpty(dependencyDepths) ? _.max(dependencyDepths) + 1 : 0
      const newVal = { value: Number(value), uncertainty: jobs.length }
      const totalConfidence = dependencies.length === dependencyDepths.length

      const calculationJobName = _.get(calculations, job.name, false)
      const neverGonnaGetBetter = calculationJobName.uncertainty <= newVal.uncertainty

      if (totalConfidence || neverGonnaGetBetter) {
        _.unset(calculations, job.name)
        _.set(depths, job.name, newVal.value)
      } else {
        _.set(calculations, job.name, newVal)
        // Put the job again in the jobs list to re-eval it on next iteration
        jobs.push(job)
      }

      return this.jobDepths(calculations, depths, jobs)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.envComponentPipeline',
      },
      es: {
        title: '@:routes.envComponentPipeline',
      },
      fr: {
        title: '@:routes.envComponentPipeline',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
// FIXME: The following could be rewritten looping over the map
.failed { background-color: cy-get-color("build", "failed"); }
.succeeded { background-color: cy-get-color("build", "succeeded"); }
.errored { background-color: cy-get-color("build", "errored"); }
.started { background-color: cy-get-color("build", "running"); }
.aborted { background-color: cy-get-color("build", "aborted"); }
.pending { background-color: cy-get-color("build", "pending"); }
.paused { background-color: cy-get-color("build", "paused"); }

.failed-color { color: cy-get-color("build", "failed"); }
.succeeded-color { color: cy-get-color("build", "succeeded"); }
.errored-color { color: cy-get-color("build", "errored"); }
.aborted-color { color: cy-get-color("build", "aborted"); }
.pending-color { color: cy-get-color("build", "pending"); }
.paused-color { color: cy-get-color("build", "paused"); }

.pipeline-failed-started { @include running-animation(cy-get-color("build", "failed"), cy-get-color("build", "black-faded")); }
.pipeline-succeeded-started { @include running-animation(cy-get-color("build", "succeeded"), cy-get-color("build", "black-faded")); }
.pipeline-errored-started { @include running-animation(cy-get-color("build", "errored"), cy-get-color("build", "black-faded")); }
.pipeline-aborted-started { @include running-animation(cy-get-color("build", "aborted"), cy-get-color("build", "black-faded")); }
.pipeline-pending-started { @include running-animation(cy-get-color("build", "pending"), cy-get-color("build", "black-faded")); }

.job-failed-started { @include running-animation(cy-get-color("build", "failed"), cy-get-color("build", "failed-faded")); }
.job-succeeded-started { @include running-animation(cy-get-color("build", "succeeded"), cy-get-color("build", "succeeded-faded")); }
.job-errored-started { @include running-animation(cy-get-color("build", "errored"), cy-get-color("build", "errored-faded")); }
.job-aborted-started { @include running-animation(cy-get-color("build", "aborted"), cy-get-color("build", "aborted-faded")); }
.job-pending-started { @include running-animation(cy-get-color("build", "pending"), cy-get-color("build", "pending-faded")); }

.banner-status {
  width: 80%;
  height: 7px;
  margin-bottom: 10px;
  border-width: 2px 4px;
  border-radius: 40px;
}

.pipeline-item {
  &__title {
    line-height: 1.5;

    a {
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .v-card {
    &__actions {
      min-height: 40px;
    }
  }
}

.pipeline-grid {
  min-width: 200px !important;
  height: 120px;
  margin: 0 36px 10px !important;
}

.parallel-grid {
  margin: 0 !important;
  padding: 0 !important;
}

.pipeline-job {
  margin: 2px !important;
  padding: 0 !important;

  span {
    flex: 0 0 0 !important;
  }
}

.joblink {
  flex-grow: 1;
}

.no-padding {
  padding: 0 !important;
}

.center {
  justify-content: center;
}
</style>
