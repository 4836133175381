var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pipeline-item"},[_c('v-card-title',{staticClass:"d-flex flex-column pipeline-item__title center"},[_c('div',{class:[("banner-status " + (_vm.pipelineStatus.status)), ( _obj = {}, _obj[("pipeline-" + (_vm.pipelineStatus.status) + "-started")] = _vm.pipelineStatus.started, _obj )]}),_c('router-link',{staticClass:"font-weight-bold font-size-base",attrs:{"to":{
          name: 'envComponentPipeline',
          params: {
            orgCanonical: _vm.orgCanonical,
            projectCanonical: _vm.pipeline.project.canonical,
            envCanonical: _vm.pipeline.environment.canonical,
            componentCanonical: _vm.pipeline.component.canonical,
            pipelineCanonical: _vm.pipeline.name,
            backRouteTo: 'pipelinesOverview',
          },
        }}},[_vm._v(" "+_vm._s(_vm.pipeline.name)+" ")])],1),_c('div',[(!_vm._.isEmpty(_vm.pipeline.jobs))?_c('v-row',{staticClass:"pipeline-grid "},_vm._l((_vm.rankedJobs),function(groupRank,keyRank,indexRank){return _c('v-col',{key:indexRank,staticClass:"parallel-grid d-flex flex-column"},_vm._l((groupRank),function(job,keyJob,indexJob){return _c('v-row',{key:indexJob,class:[
              'pipeline-job',
              _vm.getJobIdStatus(job).started ? ("job-" + (_vm.getJobIdStatus(job).status) + "-started") : _vm.getJobIdStatus(job).status ]},[_c('CyTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-col',_vm._g({staticClass:"no-padding d-flex"},on),[_c('router-link',{staticClass:"joblink",attrs:{"to":{
                      name: 'builds',
                      params: {
                        orgCanonical: _vm.orgCanonical,
                        projectCanonical: _vm.pipeline.project.canonical,
                        pipelineCanonical: _vm.pipeline.name,
                        envCanonical: _vm.pipeline.environment.canonical,
                        componentCanonical: _vm.pipeline.component.canonical,
                        jobCanonical: job.name,
                        buildId: _vm.getJobIdStatus(job).id,
                        backRouteTo: 'pipelinesOverview',
                      },
                    }}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(job.name))])])],1)}),1)}),1):_c('v-row',{staticClass:"pipeline-grid",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1)],1),_c('v-card-actions',[(_vm.pipelineAge.status)?_c('v-row',{staticClass:"align-center justify-center"},[_c('v-icon',{class:((_vm.pipelineStatus.status) + "-color")},[_vm._v(" "+_vm._s(_vm.pipelineIcon(_vm.pipelineStatus.status))+" ")]),_c('span',{class:['ml-2', ((_vm.pipelineStatus.status) + "-color"), 'font-weight-bold']},[_vm._v(" "+_vm._s(_vm.pipelineAge.status)+" ")])],1):_c('CyTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var rowTooltip = ref.on;
return [_c('v-row',_vm._g({staticClass:"align-center justify-center"},rowTooltip),[_c('v-icon',{class:((_vm.pipelineStatus.status) + "-color")},[_vm._v(" "+_vm._s(_vm.pipelineIcon(_vm.pipelineStatus.status))+" ")]),_c('span',{class:['ml-2', ((_vm.pipelineStatus.status) + "-color"), 'font-weight-bold']},[_vm._v(" "+_vm._s(_vm.pipelineAge.duration)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.pipelineAge.date))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }